@tailwind base;
@tailwind components;
@tailwind utilities;

.icon-thumbnails {
  @apply w-10 h-10;
}

.thumbnail-rounded-image {
  @apply rounded-full w-14 h-14;
}

.icon-thumbnails-small {
  @apply w-8 h-8 flex-none;
}

.section-wrapper {
  @apply mt-8;
}

.grid-col-span-1 {
  @apply col-span-1;
}

.grid-col-span-2 {
  @apply col-span-2;
}

.wysig-editor {
  max-height: 40rem;
  overflow-y: auto;
  padding: 1rem 2rem;
  cursor: text;
}

#ReactSimpleImageViewer {
  z-index: 1000 !important;
}

.react-simple-image-viewer__next,
.react-simple-image-viewer__previous,
.react-simple-image-viewer__close {
  opacity: 1 !important;
}

.react-simple-image-viewer__next,
.react-simple-image-viewer__previous {
  padding: 40px 60px !important;
  height: auto !important;
}
